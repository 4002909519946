import fetch from 'node-fetch';
import userId from '../shared/helpers/userId';
import { keys } from '../config/keys';
import _clone from 'lodash/clone';
import _isEmpty from 'lodash/isEmpty';
import {
  GET_ALL_PLANS,
  GET_TRAVELER_PLAN,
  ADDPATCH_PLAN_REQUEST,
  ADDPATCH_PLAN_SUCCESS,
  ADDPATCH_PLAN_FAILURE,
  ADDPATCH_PLAN_CLEAR,
  GET_PLAN_REQUEST,
  GET_PLAN_SUCCESS,
  GET_PLAN_FAILURE,
  ADDPATCH_DETAIL_REQUEST,
  ADDPATCH_DETAIL_SUCCESS,
  ADDPATCH_DETAIL_FAILURE,
  ADDPATCHUSER_PLAN_REQUEST,
  ADDPATCHUSER_PLAN_SUCCESS,
  ADDPATCHUSER_PLAN_FAILURE,
  ADDPATCHUSER_PLAN_CLEAR,
  GET_ALL_TRAVELERS,
  ADD_TRAVELERS_REQUEST,
  ADD_TRAVELERS_SUCCESS,
  ADD_TRAVELERS_FAILURE,
  ADD_TRAVELERS_CLEAR,
  ADD_DETAIL_TO_TRAVELER_REQUEST,
  ADD_DETAIL_TO_TRAVELER_SUCCESS,
  ADD_DETAIL_TO_TRAVELER_FAILURE,
  ADD_DETAIL_TO_TRAVELER_CLEAR,
  FETCH_PROGRAM_LINK,
  PATCH_DETAIL_FROM_TRAVELER_REQUEST,
  PATCH_DETAIL_FROM_TRAVELER_SUCCESS,
  PATCH_DETAIL_FROM_TRAVELER_FAILURE,
  PATCH_DETAIL_FROM_TRAVELER_CLEAR,
  PATCH_PLAN_USER_REQUEST,
  PATCH_PLAN_USER_SUCCESS,
  PATCH_PLAN_USER_FAILURE,
  PATCH_PLAN_USER_CLEAR,
  SEND_EMAIL_TO_TRAVELERS,
  SEND_EMAIL_TO_TRAVELERS_FAILURE,
  SEND_EMAIL_TO_TRAVELERS_CLEAR,
  SEND_EMAIL_TO_TRAVELERS_SUCCESS,
  BATCH_UPDATE_PLAN_REGISTRATION_STATUS,
  BATCH_UPDATE_PLAN_REGISTRATION_STATUS_FAILURE,
  BATCH_UPDATE_PLAN_REGISTRATION_STATUS_CLEAR,
  BATCH_UPDATE_PLAN_REGISTRATION_STATUS_SUCCESS,
  BATCH_UPDATE_PLAN_STATUS,
  BATCH_UPDATE_PLAN_STATUS_FAILURE,
  BATCH_UPDATE_PLAN_STATUS_CLEAR,
  BATCH_UPDATE_PLAN_STATUS_SUCCESS,
  FETCH_TRANSPORTATION_DETAILS,
  FETCH_HOUSING_DETAILS,
  FETCH_ACTIVITY_DETAILS,
  CREATE_PLAN_TERM_REQUEST,
  CREATE_PLAN_TERM_SUCCESS,
  CREATE_PLAN_TERM_FAILURE,
  DELETE_PLAN_REQUEST,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_FAILURE
} from './types';
import { Cookies } from 'react-cookie';

export function getAllPlans(travelerID="") {
  const cookies = new Cookies();
  const token = cookies.get('token');
  let url = `${keys.baseUri}/api/plans`;
  if(travelerID) {
    url = `${keys.baseUri}/api/plans?traveler_id=${travelerID}`;
  }
  return function(dispatch) {
    fetch(url, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: GET_ALL_PLANS,
          payload: data,
        });
      });
  };
}

export function getTravelerPlan(travelerID, planID) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  let url = `${keys.baseUri}/api/plans/${planID}/traveler_plan_forms?traveler_id=${travelerID}`;

  return function(dispatch) {
    fetch(url, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: GET_TRAVELER_PLAN,
          payload: data,
        });
      });
  };
}

export function getPlan(id, isProxyUser = false) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: GET_PLAN_REQUEST });
    fetch(`${keys.baseUri}/api/plans/${id}`, {
      method: 'GET',
      headers: token,
    }).then(res => {
      if (res.status > 400) {
        res.json().then(data => {
          dispatch({
            type: GET_PLAN_FAILURE,
            payload: data.message,
          });
        });
      } else {
        res.json().then(data => {
          data.isProxyUser = isProxyUser;
          dispatch({
            type: GET_PLAN_SUCCESS,
            payload: data,
          });
        });
      }
    });
  };
}

export function addPlan(values, type = "myself") {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: ADDPATCH_PLAN_REQUEST });

    const url = type === "myself" ? "/api/plans" : "/api/traveler/plans";
    let payload = values;

    if(type === "myself"){
      payload = {
        plan: values
      }
    }
    
    fetch(`${keys.baseUri}${url}`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(payload),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: ADDPATCH_PLAN_SUCCESS,
          payload: data,
        });
        dispatch({
          type: ADDPATCH_PLAN_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: ADDPATCH_PLAN_FAILURE,
          payload: error.toString(),
        });
      });
  };
}





export function patchPlan(planId, values) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: ADDPATCH_PLAN_REQUEST });
    fetch(`${keys.baseUri}/api/plans/${planId}`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify({ plan: values }),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: ADDPATCH_PLAN_SUCCESS,
          payload: data,
        });
        dispatch({
          type: ADDPATCH_PLAN_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: ADDPATCH_PLAN_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function addDetail(planId, values, type) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: ADDPATCH_DETAIL_REQUEST });
    fetch(`${keys.baseUri}/api/plans/${planId}/${type}`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: ADDPATCH_DETAIL_SUCCESS,
          payload: data,
        });
        dispatch({
          type: ADDPATCH_PLAN_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: ADDPATCH_DETAIL_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function patchDetail(planId, detailId, values, itineraryType) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: ADDPATCH_DETAIL_REQUEST });
    fetch(`${keys.baseUri}/api/${itineraryType}/${detailId}`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: ADDPATCH_DETAIL_SUCCESS,
          payload: data,
        });
        dispatch({
          type: ADDPATCH_PLAN_CLEAR,
        });

        // dispatch(getPlan(planId));
      })
      .catch(error => {
        dispatch({
          type: ADDPATCH_DETAIL_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function addUserToPlan(planId, values) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: ADDPATCHUSER_PLAN_REQUEST });
    fetch(`${keys.baseUri}/api/plans/${planId}/plans_users`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: ADDPATCHUSER_PLAN_SUCCESS,
          payload: data,
        });
        dispatch({
          type: ADDPATCHUSER_PLAN_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: ADDPATCHUSER_PLAN_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function patchUserToPlan(planId, values) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: ADDPATCHUSER_PLAN_REQUEST });
    fetch(`${keys.baseUri}/api/plans/${planId}/plans_users`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: ADDPATCHUSER_PLAN_SUCCESS,
          payload: data,
        });
        dispatch({
          type: ADDPATCHUSER_PLAN_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: ADDPATCHUSER_PLAN_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function getAllTravelers() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/plan_travelers`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: GET_ALL_TRAVELERS,
          payload: data,
        });
      });
  };
}

export function addTravelerToPlan(planId, values, type) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  let bodyObject = type === 'csv_upload' ? { csv_upload: values } : { traveler: values };
  return function(dispatch) {
    dispatch({ type: ADD_TRAVELERS_REQUEST });
    fetch(`${keys.baseUri}/api/plans/${planId}/invitations`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(bodyObject),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: ADD_TRAVELERS_SUCCESS,
          payload: { data: data },
        });
        dispatch({
          type: ADD_TRAVELERS_CLEAR,
        });
        dispatch(getPlan(planId));
      })
      .catch(error => {
        dispatch({
          type: ADD_TRAVELERS_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function addDetailToTraveler(values) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: ADD_DETAIL_TO_TRAVELER_REQUEST });
    fetch(`${keys.baseUri}/api/itineraries`, {
      method: 'POST',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: ADD_DETAIL_TO_TRAVELER_SUCCESS,
          payload: { data: data },
        });
        dispatch({
          type: ADD_DETAIL_TO_TRAVELER_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: ADD_DETAIL_TO_TRAVELER_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function patchDetailFromTraveler(values) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: PATCH_DETAIL_FROM_TRAVELER_REQUEST });
    fetch(`${keys.baseUri}/api/itineraries`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: PATCH_DETAIL_FROM_TRAVELER_SUCCESS,
          payload: { data: data },
        });
        dispatch({
          type: PATCH_DETAIL_FROM_TRAVELER_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: PATCH_DETAIL_FROM_TRAVELER_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchProgramLinks() {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/plan_programs`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_PROGRAM_LINK,
          payload: data,
        });
      });
  };
}

export function patchPlanUser(planUserId, values) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: PATCH_PLAN_USER_REQUEST });
    fetch(`${keys.baseUri}/api/plans_users/${planUserId}`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: PATCH_PLAN_USER_SUCCESS,
          payload: { data: data },
        });
        dispatch({
          type: PATCH_PLAN_USER_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: PATCH_PLAN_USER_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function sendEmailToTravelers(values) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  const formPayLoad = new FormData();
  formPayLoad.append('message[subject]', values.message.subject);
  formPayLoad.append('message[body]', values.message.body);
  formPayLoad.append('plans_user_ids', values.plans_user_ids);
  formPayLoad.append('message[attachment_count]', values.message.attachment_count);
  !_isEmpty(values.message.attachments) &&
    values.message.attachments.forEach((attachment, index) => {
      formPayLoad.append(`message[attachment${index}]`, attachment);
    });

  let cloneToken = _clone(token);
  delete cloneToken['Accept'];
  delete cloneToken['Content-Type'];

  return function(dispatch) {
    dispatch({ type: SEND_EMAIL_TO_TRAVELERS });
    fetch(`${keys.baseUri}/api/email_messages`, {
      method: 'POST',
      headers: cloneToken,
      body: formPayLoad,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: SEND_EMAIL_TO_TRAVELERS_SUCCESS,
          payload: { data: data },
        });
        dispatch({
          type: SEND_EMAIL_TO_TRAVELERS_CLEAR,
        });
      })
      .catch(error => {
        dispatch({
          type: SEND_EMAIL_TO_TRAVELERS_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function batchUpdatePlanRegistrationStatus(values, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: BATCH_UPDATE_PLAN_REGISTRATION_STATUS });
    fetch(`${keys.baseUri}/api/plan_batch_actions/update_plan_registatration_statuses`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {

        if(callback && typeof callback === "function"){
          callback(true, `${values.plan_ids.length} Plan${values.plan_ids.length > 0 ? "(s)" : ""} updated`);
        }

        dispatch({
          type: BATCH_UPDATE_PLAN_REGISTRATION_STATUS_SUCCESS,
          payload: { data: data },
        });
        dispatch({
          type: BATCH_UPDATE_PLAN_REGISTRATION_STATUS_CLEAR,
        });
        dispatch(getAllPlans());
      })
      .catch(error => {

        if(callback && typeof callback === "function"){
          callback(false, error.toString());
        }

        dispatch({
          type: BATCH_UPDATE_PLAN_REGISTRATION_STATUS_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function batchUpdatePlanStatus(values, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return function(dispatch) {
    dispatch({ type: BATCH_UPDATE_PLAN_STATUS });
    fetch(`${keys.baseUri}/api/plan_batch_actions/update_plan_statuses`, {
      method: 'PATCH',
      headers: token,
      body: JSON.stringify(values),
    })
      .then(res => res.json())
      .then(data => {

        if(callback && typeof callback === "function"){
          callback(true, `${values.plan_ids.length} Plan${values.plan_ids.length > 0 ? "(s)" : ""} updated`);
        }

        dispatch({
          type: BATCH_UPDATE_PLAN_STATUS_SUCCESS,
          payload: { data: data },
        });
        dispatch({
          type: BATCH_UPDATE_PLAN_STATUS_CLEAR,
        });
        dispatch(getAllPlans());

      })
      .catch(error => {

        if(callback && typeof callback === "function"){
          callback(false, error.toString());
        }

        dispatch({
          type: BATCH_UPDATE_PLAN_STATUS_FAILURE,
          payload: error.toString(),
        });
      });
  };
}

export function fetchTransportationDetails(version2 = false) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  let params = '';
  if (version2) {
    params = '?version=2';
  }
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler_transportations${params}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        let dataSet = {};
        dataSet.data = data;
        dispatch({
          type: FETCH_TRANSPORTATION_DETAILS,
          payload: data,
          version: version2,
        });
      });
  };
}

export function fetchHousingDetails(version2 = false) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  let params = '';
  if (version2) {
    params = '?version=2';
  }
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler_housings${params}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_HOUSING_DETAILS,
          payload: data,
          version: version2,
        });
      });
  };
}

export function fetchActivityDetails(version2 = false) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  let params = '';
  if (version2) {
    params = '?version=2';
  }
  return function(dispatch) {
    fetch(`${keys.baseUri}/api/traveler_activities${params}`, {
      method: 'GET',
      headers: token,
    })
      .then(res => res.json())
      .then(data => {
        dispatch({
          type: FETCH_ACTIVITY_DETAILS,
          payload: data,
          version: version2,
        });
      });
  };
}

export function createTravelPlanFromTerm(payload, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return async dispatch => {
    try {
      let postData = {
        term_ids: payload.term_ids,
        plan_status_id: payload.plan_status_id,
        plan_type_id: payload.plan_type_id,
        plan_registration_status_id: payload.plan_registration_status_id,
        plan_availability: payload.plan_availability,
      };

      dispatch({
        type: CREATE_PLAN_TERM_REQUEST,
      });

      let response = await fetch(`${keys.baseUri}/api/plans/create_from_program_term`, {
        method: 'POST',
        headers: token,
        body: JSON.stringify(postData),
      });

      if (response.status === 200 || response.status === 201) {
        let data = await response.json();
        dispatch({
          type: CREATE_PLAN_TERM_SUCCESS,
          payload: data,
        });

        if (callback && typeof callback === 'function') {
          callback(true, 'Plans created');
        }
      } else {
        throw new Error('Something went wrong');
      }
    } catch (e) {
      dispatch({
        type: CREATE_PLAN_TERM_FAILURE,
        payload: e.message,
      });

      callback(false, e.message);
    }
  };
}


export function deletePlan(planId, callback = null) {
  const cookies = new Cookies();
  const token = cookies.get('token');
  return async dispatch => {
    try {

      dispatch({
        type: DELETE_PLAN_REQUEST,
      });

      let response = await fetch(`${keys.baseUri}/api/plans/${planId}`, {
        method: 'DELETE',
        headers: token,
      });

      if (response.status >= 200 || response.status < 300) {

        dispatch({
          type: DELETE_PLAN_SUCCESS,
          payload: {},
        });

        if (callback && typeof callback === 'function') {
          callback(true, 'Plan deleted');
        }
      } else {
        throw new Error('Something went wrong');
      }
    } catch (e) {
      console.log("Error is ", e);
      dispatch({
        type: DELETE_PLAN_FAILURE,
        payload: e.message,
      });

      callback(false, e.message);
    }
  };
}
