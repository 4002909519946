exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2NlgQ{padding:30px 45px;display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;gap:30px}._2bXPk{font-family:Open Sans;font-size:16px;font-weight:400;line-height:22.4px;letter-spacing:.005em;text-align:center}.lzlxs{padding:0}._2Lfn9{border-top:1px solid #999;border-top:1px solid var(--gray-medium2,#999);-ms-flex-pack:end;justify-content:end;padding:26px 20px;-ms-flex-align:center;align-items:center;gap:8px;-ms-flex-item-align:stretch;align-self:stretch}._1nN6A,._2Lfn9{display:-ms-flexbox;display:flex}._1nN6A{gap:20px}._35lF1{display:-ms-flexbox;display:flex;padding:6px 12px;-ms-flex-pack:center;justify-content:center;-ms-flex-align:center;align-items:center;gap:8px;border-radius:4px;border:1px solid #446372;border:1px solid var(--Theme-Primary,#446372);background:rgba(191,41,41,0);background:var(--HitBox,rgba(191,41,41,0));color:#446372;color:var(--Theme-Primary,#446372);font-family:Open Sans;font-size:16px;font-style:normal;font-weight:700;line-height:150%}.VR6qw{color:#255a94}._1VRxa,.VR6qw{background:none;border:none;font-weight:700;text-decoration:underline;cursor:pointer;padding:0;font-size:16px;font-family:Open Sans,sans-serif}._1VRxa{color:#8b1313}._1NXz3{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between;width:100%}._1FciW{-ms-flex-pack:right;justify-content:right}", ""]);

// exports
exports.locals = {
	"container": "_2NlgQ",
	"text": "_2bXPk",
	"modalBody": "lzlxs",
	"footer": "_2Lfn9",
	"buttonContainer": "_1nN6A",
	"cancelButton": "_35lF1",
	"createButton": "VR6qw",
	"removeAccessButton": "_1VRxa",
	"buttonParentContainer": "_1NXz3",
	"buttonParentContainerRight": "_1FciW"
};