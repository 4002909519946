import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import toastMessageStyles from '../../sites/viaGlobal/components/toastMessage/toastMessage.scss';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import PrimaryButton from '../PrimaryButton';
import userId from '../../shared/helpers/userId';
import BaseModalHeader from '../baseModal/BaseModalHeader';
import {
  fetchTravelerProxyUsers,
  removeProxyAccess,
  travelerAddExistingProxy,
} from '../../actions/proxyManagmentActions';
import ProfileSelectInput from '../../sites/travelerProfile/components/base/inputs/ProfileSelectInput';
import { getUserDetails } from '../../actions/profileActions';

function AddEditExistingProxyUserModal({ show, handleClose, createNewOne, proxyUserId = null }) {
  const dispatch = useDispatch();
  const { control, handleSubmit, setValue, watch } = useForm({
    defaultValues: {
      userId: proxyUserId || '',
    },
  });

  const {
    travelerProxyUserData,
    loading: proxyUserLoading,
    error,
    addExistingProxyLoading,
    removeProxyLoading,
  } = useSelector(state => state.proxy);



  const travelerOptions = travelerProxyUserData?.map(item => {
    const name = [item.first_name, item.last_name].filter(Boolean).join(' ');
    const universityId = item.university_id === '0' || item.university_id === 0 || !item.university_id ? '' : item.university_id;
    const label = `${name}${name ? ', ' : ''}${item.email}${universityId ? ` (${universityId})` : ''}`;
    return {
      label,
      value: item.id,
    };
  })?.sort((a, b) => a.label.localeCompare(b.label));



  const removeAccess = () => {
    dispatch(
      removeProxyAccess(status => {
        if (status) {
          toast('Proxy access removed.', {
            type: 'success',
            className: toastMessageStyles['successContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['successBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });

          dispatch(getUserDetails(userId));

          handleClose();
        } else {
          toast('Something went wrong, please try again later', {
            type: 'error',
            className: toastMessageStyles['failureContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['failureBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });
        }
      }),
    );
  };

  const onSubmit = data => {
    //setFormValues(data);
    const payload = {
      proxy_user_id: data.userId,
    };
    dispatch(
      travelerAddExistingProxy(payload, (status, message) => {
        if (status) {
          toast('Proxy added.', {
            type: 'success',
            className: toastMessageStyles['successContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['successBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });

          dispatch(getUserDetails(userId));

          handleClose();
        } else {
          toast('Something went wrong, please try again later', {
            type: 'error',
            className: toastMessageStyles['failureContainer'],
            toastClassName: toastMessageStyles['wrapper'],
            bodyClassName: toastMessageStyles['failureBody'],
            progressClassName: toastMessageStyles['progress-bar'],
          });
        }
      }),
    );
  };

  useEffect(() => {
    dispatch(fetchTravelerProxyUsers());
  }, []);

  useEffect(() => {
    if (proxyUserId) {
      setValue('userId', parseInt(proxyUserId));
    }
  }, [proxyUserId]);

  return (
    <>
      <Modal show={show} onHide={handleClose} centered size={'md'}>
        <>
          <BaseModalHeader heading={`${proxyUserId ? 'Change' : 'Add'} Existing Proxy User`} onClose={handleClose} />
          <Modal.Body className={styles.modalBody}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Container className={styles.container}>
                <div className={styles.text}>
                  Proxy users can create and edit travel plans for you. They can also modify any forms on your behalf,
                  as long as those forms have not been submitted. If your proxy creator is not listed,{' '}
                  <button className={styles.createButton} onClick={createNewOne}>
                    create a new one.
                  </button>
                </div>

                <div>
                  <Controller
                    name="userId"
                    control={control}
                    rules={{ required: 'Please select proxy.' }}
                    render={({ field }) => (
                      <>
                        <ProfileSelectInput
                          id="userId"
                          label="Select Proxy Creator"
                          placeholder="Type to select proxy creator"
                          options={travelerOptions}
                          handleOnChange={val => field.onChange(val.value)}
                          value={travelerOptions?.find(i => parseInt(i.value) === parseInt(field.value))}
                          isLoading={proxyUserLoading}
                          isRequired
                        />
                      </>
                    )}
                  />
                </div>
              </Container>

              <div className={styles.footer}>
                <div
                  className={`${styles.buttonParentContainer} ${!proxyUserId ? styles.buttonParentContainerRight : ''}`}
                >
                  {proxyUserId && (
                    <button className={styles.removeAccessButton} type={'button'} onClick={removeAccess}>
                      Remove Access
                    </button>
                  )}

                  <div className={styles.buttonContainer}>
                    <button type="button" className={styles.cancelButton} onClick={handleClose}>
                      Cancel
                    </button>
                    <PrimaryButton text={'Save'} loading={addExistingProxyLoading} type={'submit'} />
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </>
      </Modal>
    </>
  );
}

export default withStyles(styles, toastMessageStyles)(AddEditExistingProxyUserModal);
