exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._10Scs{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:start;justify-content:flex-start;gap:12px;width:388.982px;height:69.214px;-ms-flex-negative:0;flex-shrink:0;border:1px solid #ddd;border-radius:8px;background:#fff;cursor:pointer;font-family:Open Sans,sans-serif;font-size:16px;font-style:normal;font-weight:600;line-height:140%;color:#373737;-webkit-box-shadow:0 4px 6px rgba(0,0,0,.1);box-shadow:0 4px 6px rgba(0,0,0,.1);padding:0 30px}._3PUJt{width:24px;height:24px}._1rvkV{-ms-flex-positive:1;flex-grow:1;text-align:left}", ""]);

// exports
exports.locals = {
	"dynamicButton": "_10Scs",
	"icon": "_3PUJt",
	"text": "_1rvkV"
};