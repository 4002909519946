import React from 'react';
import Modal from 'react-bootstrap/Modal';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import styles from './index.module.css';

import searchIcon from '../../shared/images/search-icon2.png';
import inviteUserIcon from '../../shared/images/invite-user-icon.png';

import BaseModalHeader from '../baseModal/BaseModalHeader';
import TextIconButton from '../TextIconButton';

function AddProxyModal({ show, handleClose, onButtonClick }) {
  return (
    <>
      <Modal show={show} onHide={handleClose} centered size={'md'}>
        <>
          <BaseModalHeader heading={'Invite Proxy'} onClose={handleClose} />
          <Modal.Body className={styles.modalBody}>
            <div className={styles.buttonContainer}>
              <div className={styles.buttonWrapper}>
                <TextIconButton icon={searchIcon} text={'Search for Existing Proxy User'} width={'350px'} onClick={() => onButtonClick("ExistingProxy")} />
              </div>

              <div className={styles.buttonWrapper}>
                <TextIconButton icon={inviteUserIcon} text={'Invite New Proxy User'} width={'350px'} onClick={() => onButtonClick("InviteNewProxy")} />
              </div>
            </div>
          </Modal.Body>
        </>
      </Modal>
    </>
  );
}

export default withStyles(styles)(AddProxyModal);
