import React from 'react';
import styles from './index.module.css';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

const TravelerProxyBanner = ({ orgName, onClick, banner = 'blue', proxyName = '', proxyUniversityId = '' }) => {
  if (banner === 'blue') {
    return (
      <div className={styles.infoAlert}>
        Only you and {orgName} administrators can create plans on your behalf. To change this,{' '}
        <button onClick={() => onClick('blue')} className={styles.infoAlertButton}>
          click here
        </button>
        .
      </div>
    );
  }

  return (
    <div className={styles.infoAlertGrey}>
      Plans can be created on your behalf by {proxyName}
      {proxyUniversityId && proxyUniversityId !== '0' ? ` (${proxyUniversityId})` : ''}. To change this,{' '}
      <button onClick={() => onClick('grey')} className={styles.infoAlertButton}>
        click here
      </button>
      .
    </div>
  );
};

export default withStyles(styles)(TravelerProxyBanner);
