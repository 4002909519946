import React from "react";
import styles from "./index.module.css";
import withStyle from "isomorphic-style-loader/lib/withStyles";

const TextIconButton = ({ icon, text, onClick, width = "388.982px" }) => {
  return (
    <button className={styles.dynamicButton} onClick={onClick} style={{ width }}>
      <img src={icon} alt="icon" className={styles.icon} />
      <span className={styles.text}>{text}</span>
    </button>
  );
};

export default withStyle(styles)(TextIconButton);
