exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3s20H{border-radius:4px;border:1px solid #a9cada;background:#deecff;padding:16px;font-style:normal;font-weight:400;line-height:150%;margin:20px}._3s20H,.eK7GN{color:#255a94;font-family:Open Sans,sans-serif;font-size:16px}.eK7GN{background:none;border:none;font-weight:700;text-decoration:underline;cursor:pointer;padding:0}._5Jyyz{border-radius:4px;border:1px solid #d7d7d7;background:#eee;padding:16px;color:#373737;font-family:Open Sans,sans-serif;font-size:16px;font-style:normal;font-weight:400;line-height:150%;margin:20px}", ""]);

// exports
exports.locals = {
	"infoAlert": "_3s20H",
	"infoAlertButton": "eK7GN",
	"infoAlertGrey": "_5Jyyz"
};